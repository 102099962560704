<template>
  <div>
    <div id="live-flow-dialog-container-amazon-redshift" />
    <div id="live-flow-dialog-loading-container-amazon-redshift" />
    <v-dialog
      v-if="!datasetsOfSelectedProject.length || loadings.datasets"
      v-model="loadings.datasets"
      hide-overlay
      persistent
      attach="#live-flow-dialog-loading-container-amazon-redshift"
    >
      <!-- Loading Dialog -->
      <div
        class="d-flex flex-column justify-space-between"
        style="width: 200px; height: 150px; padding: 10px"
      >
        <p
          class="text-primary m-0"
          style="display: inline-block"
        >
          Opening project...
        </p>
        <div class="d-flex justify-center">
          <v-progress-circular
            indeterminate
            color="primary"
            :size="100"
            style="margin-top: -50px; margin-bottom: -50px; opacity: 0.1"
          />
        </div>
        <p
          style=""
          class="text-primary small m-0"
        >
          We are looking for the structure of your table..
        </p>
      </div>
    </v-dialog>
    <v-dialog
      v-else
      v-model="liveFlowFunnel"
      persistent
      attach="#live-flow-dialog-container-amazon-redshift"
    >
      <div class="d-flex flex-column">
        <v-stepper
          v-model="step"
          class="elevation-0 pt-0"
          style="max-height: calc(100% - 56px)"
          flat
        >
          <v-stepper-header
            flat
            class="elevation-0 dialog-header"
          >
            <v-stepper-step
              :complete="step > 1"
              color="var(--primary-color-dark-green)"
              :step="1"
            >
              Dimensions & Metrics
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :complete="step > 2"
              color="var(--primary-color-dark-green)"
              :step="2"
            >
              KPIs definition
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              :step="3"
              color="var(--primary-color-dark-green)"
            >
              Comparison
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content :step="1">
              <div
                v-if="step.toString() === '1'"
                class="d-flex flex-column"
              >
                <div class="d-flex flex-column justify-start text-left mb-4">
                  <label class="mb-0">
                    Dataset to use
                  </label>
                  <v-select
                    v-model="edition.dataset"
                    :disabled="loadings.datasets"
                    :loading="loadings.datasets"
                    :items="datasetsOfSelectedProject"
                    variant="solo-inverted"
                    hide-details
                  >
                    <template #prepend-item>
                      <v-list-item class="pb-2 pt-1">
                        <v-list-item-content class="p-0">
                          <v-list-item-title>
                            <v-text-field
                              v-model="searchDataset"
                              hide-details
                              class="m-0 p-0 datama-text-input"
                              placeholder="Search..."
                            >
                              <template #prepend-inner>
                                <v-icon> mdi-magnify </v-icon>
                              </template>
                            </v-text-field>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template #selection="{ item }">
                      <span>{{ item.text || item }}</span>
                    </template>
                    <template #item="{ item, attrs, on }">
                      <v-list-item
                        v-bind="attrs"
                        :class="
                          item.show == null || item.show
                            ? 'd-flex'
                            : 'datama-hidden-selection'
                        "
                        v-on="on"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ typeof item === "object" ? item.text : item }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </div>
                <div class="d-flex flex-column justify-start text-left mb-4">
                  <label class="mb-0">
                    Table to use (from
                    <span class="font-weight-bold">{{ selectedDataset }}</span>)
                  </label>
                  <v-select
                    v-model="edition.table"
                    :disabled="loadings.tables"
                    :loading="loadings.tables"
                    :items="tablesOfSelectedDatasetFiltered"
                    variant="solo-inverted"
                    hide-details
                  >
                    <template #prepend-item>
                      <v-list-item class="pb-2 pt-1">
                        <v-list-item-content class="p-0">
                          <v-list-item-title>
                            <v-text-field
                              v-model="searchTable"
                              hide-details
                              class="m-0 p-0 datama-text-input"
                              placeholder="Search..."
                            >
                              <template #prepend-inner>
                                <v-icon> mdi-magnify </v-icon>
                              </template>
                            </v-text-field>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template #selection="{ item }">
                      <span>{{ item.text || item }}</span>
                    </template>
                    <template #item="{ item, attrs, on }">
                      <v-list-item
                        v-bind="attrs"
                        :class="
                          item.show == null || item.show
                            ? 'd-flex'
                            : 'datama-hidden-selection'
                        "
                        v-on="on"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ typeof item === "object" ? item.text : item }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </div>
                <div
                  v-if="edition.dataset && (edition.table || loadings.schema)"
                  class="d-flex flex-column justify-start text-left mb-4"
                >
                  <label class="mb-0">
                    Columns to use (from
                    <span class="font-weight-bold">{{ selectedDataset }}.{{
                      selectedTable
                    }}</span>)
                  </label>
                  <v-col
                    v-if="admin"
                    class="text-right"
                  >
                  <!-- Dimension scoring -->
                    <!-- <v-switch
                      v-if="!isNextButtonDisabled"
                      v-model="switches.dimension_scoring"
                      label="Enable dimension scoring"
                      @change="runDimensionScoring()"
                    /> -->
                  </v-col>
                  <InfoMessage
                    v-if="firstStepError"
                    styles="color:orange;font-size: 12px; font-style: italic; font-weight: 300;"
                    icon="mdi mdi-information-outline"
                    class="text-left"
                    :message="firstStepError"
                  />
                  <v-chip-group
                    :key="key"
                    v-model="edition.columns"
                    column
                    multiple
                    show-arrows
                  >
                    <v-chip v-if="loadings.schema">
                      <v-progress-circular size="20" />
                      Loading ...
                    </v-chip>
                    <v-chip
                      v-for="(col, index) in edition.tableSchema"
                      v-else
                      :key="col.name + index"
                      filter
                      bottom
                      :value="col.name"
                      outlined
                      :color="columnColor(col)"
                      :class="columnKind(col)"
                      @click.stop="() => handleColumnAddition(col)"
                    >
                      <v-icon
                        small
                        left
                        aria-hidden="false"
                      >
                        {{ colIcon(col) }}
                      </v-icon>
                      <v-divider />
                      <v-select
                        v-if="metrics.includes(col.name)"
                        v-model="edition.aggregation[col.name]"
                        :items="aggregationMethods[colToType(col)]"
                        hide-details="auto"
                        class="m-0 p-0 w-100"
                        @click.stop
                      />
                      <v-divider />
                      {{ col.name }}
                      <progress
                        v-if="switches.dimension_scoring"
                        :value="dimension_score[col.name]"
                        max="100"
                      />
                    </v-chip>
                  </v-chip-group>
                </div>
              </div>
            </v-stepper-content>
            <v-stepper-content :step="2">
              <DataMaMetricRelation v-if="step === 2 && metrics.length" />
            </v-stepper-content>
            <v-stepper-content :step="3">
              <TableConnectSolutionStep
                v-if="step === 3"
                :key="key"
                v-model="edition"
                :schemas="edition.tableSchema"
              />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <div class="d-flex flex-nowrap pl-5 pr-5 pb-2 pt-2 justify-end">
          <!--DimensionScoring-->
          <!-- <v-btn
            v-if="step === 1 && admin"
            class="text-primary border ml-auto mr-0"
            style="
              background: transparent;
              border: 1px solid var(--datama-primary) !important;
            "
            @click.stop="() => runDimensionScoring()"
          >
            Ranking
          </v-btn> -->
          <!--DryRun-->
          <v-btn
            v-if="step === 3"
            :disabled="!!lastStepError"
            class="text-primary border ml-auto mr-0"
            style="
              background: transparent;
              border: 1px solid var(--datama-primary) !important;
            "
            :loading="loadingDryRun"
            @click.stop="() => launchDryRun()"
          >
            <div
              v-if="totalBytesProcessed"
              class="d-flex flex-nowrap"
            >
              <v-icon small>
                mdi-refresh
              </v-icon>
              <InfoMessage
                class="mr-auto ml-0 text-primary"
                styles="display:block;"
                icon="mdi mdi-information-outline"
                :message="
                  'This query will process ' + totalBytesProcessed + ' when run'
                "
              />
            </div>
            <div v-else>
              <v-icon> mdi-play </v-icon>
              <span>Dry run</span>
            </div>
          </v-btn>
          <v-divider
            v-if="step === 3 || step === 1"
            class="ml-2 mr-2"
            vertical
          />
          <!--Close-->
          <v-btn
            class="bg-transparent border border-black black--text ml-0 mr-0"
            @click.stop="close()"
          >
            <v-icon>mdi-close</v-icon>
            <span>Close</span>
          </v-btn>
          <v-divider
            class="ml-2 mr-2"
            vertical
          />
          <!--Previous-->
          <v-btn
            class="ml-0 mr-2"
            :disabled="step === 1"
            @click.stop="step -= 1"
          >
            <v-icon>mdi-arrow-left</v-icon>
            <span>Previous</span>
          </v-btn>
          <!--Next-->
          <v-btn
            :disabled="isNextButtonDisabled || (step === 3 && !!lastStepError)"
            :class="
              isNextButtonDisabled || (step === 3 && lastStepError)
                ? null
                : 'bg-primary'
            "
            :style="
              isNextButtonDisabled || (step === 3 && lastStepError)
                ? 'background-color: rgba(0,0,0,.1) !important;color: white !important;'
                : null
            "
            class="ml-0 mr-0"
            @click.stop="() => (step === 3 ? apply() : (step += 1))"
          >
            <v-icon>mdi-arrow-right</v-icon>
            <span>{{ step === 3 ? "Done" : "Next" }}</span>
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import DataMaMetricRelation from "../Inputs/DataMaMetricRelation.vue";
import DataMaLogicAbstract from "@/components/Structure/Mixins/DataMaLogicAbstract.vue";
import InfoMessage from "../../../Structure/Misc/InfoMessage.vue";
import TableConnectSolutionStep from "./TableConnectSolutionStep.vue";
import LiveFlowAbstract from "../Mixins/LiveFlowAbstract.vue";
import { aggregation_function } from "@/config";
import { COMPUTE_STATES } from "@/config";
import { solution } from "@/config/solution";

export default {
  name: "LiveFlowDialogAmazonRedshift",
  components: {
    TableConnectSolutionStep,
    InfoMessage,
    DataMaMetricRelation,
  },
  mixins: [DataMaLogicAbstract, LiveFlowAbstract],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      schemaOf: false,
      tableOf: false,
      loadingDryRun: false,
      switches: {
        dimension_scoring: false,
      },
      searchDataset: "",
      searchTable: "",
      dimension_score: [],
      loadings: {
        datasets: true,
        tables: false,
        schema: false,
      },
      totalBytesProcessed: false,
      edition: this.value,
      step: 1,
      key: 0,
      aggregationMethods: aggregation_function
    };
  },
  computed: {
    tablesOfSelectedDatasetFiltered() {
      return this.tablesOfSelectedDataset.map((table) => ({
        text: table,
        value: table,
        show:
          !this.searchTable ||
          (!!this.searchTable &&
            table.toLowerCase().indexOf(this.searchTable.toLowerCase()) !== -1),
      }));
    },
    selectedSolutionConfiguration() {
      return solution[this.edition.solution];
    },
    lastStepError() {
      let result = false;
      if (!this.selectedSolutionConfiguration) {
        result = "You need to choose a solution";
      } else {
        if (this.selectedSolutionConfiguration.dimensions.comparison.required) {
          result = this.comparisons.length
            ? false
            : "You need to define at least your comparison to continue with Datama Solutions";
        } else {
          if (
            this.selectedSolutionConfiguration.dimensions.mode[1] === "anomaly"
          ) {
            result = this.$store.state.shared.inputs.anomaly.value
              ? false
              : "You need to define the columns to use for Anomaly Detection";
          } else {
            result = this.workbookFilters.some((filters) => filters.length)
              ? false
              : "To reduce cost caused by data pulling, you must define at least 1 filter to continue with Datama Solutions";
          }
        }
      }
      return result;
    },
    isNextButtonDisabled() {
      if (this.step.toString() === "1") {
        return !(this.dimensions?.length && this.metrics?.length);
      } else if (this.step.toString() === "2") {
        return !this.steps?.length;
      }
      return false;
    },
    liveFlow: {
      get() {
        let sourceBlockOfTableConnect;
        if (this.isLiveFlow) {
          sourceBlockOfTableConnect =
            this.$store.state.prep.flowConfigurationList
              .filter((f) =>
                f.blocks.filter((b) => b.configuration.isTableConnect)
              )
              .map(
                (f) => f.blocks.filter((b) => b.configuration.isTableConnect)[0]
              )[0];
        }
        return {
          metrics: this.$store.state.shared.metrics,
          dimensions: this.$store.state.shared.dimensions,
          filters: this.$store.state.shared.inputs.filters,
          comparisons: this.$store.state.shared.inputs.comparisons,
          steps: this.$store.state.shared.steps,
          source: sourceBlockOfTableConnect,
          funnel: this.liveFlowFunnel,
        };
      },
      set(v) {
        let sourceBlockOfTableConnect =
          this.$store.state.prep.flowConfigurationList
            .filter((f) =>
              f.blocks.filter((b) => b.configuration.isTableConnect)
            )
            .map(
              (f) => f.blocks.filter((b) => b.configuration.isTableConnect)[0]
            )[0];
        this.$set(this, "metrics", v.metrics);
        this.$set(this, "dimensions", v.dimensions);
        this.$set(this, "steps", v.steps);
        this.$set(this.$store.state.shared.inputs, "filters", v.filters);
        this.$set(
          this.$store.state.shared.inputs,
          "comparisons",
          v.comparisons
        );
        this.$set(this, "liveFlowFunnel", v.funnel);
        this.$set(
          sourceBlockOfTableConnect,
          "configuration",
          v.source.configuration
        );
      },
    },
    firstStepError() {
      let hasMetrics = !!this.metrics.length;
      let hasDimensions = !!this.dimensions.length;
      if (!hasMetrics && !hasDimensions) {
        return "You need to select at least 1 metric and 1 dimension to be used in Datama Solution";
      } else if (!hasMetrics) {
        return "You need at least 1 metric to be used in Datama Solution";
      } else if (!hasDimensions) {
        return "You need at least 1 dimension to be used in Datama Solution";
      }
      return false;
    },
    total: {
      get() {
        return this.$store.state.shared.inputs.step_total;
      },
      set(v) {
        this.$set(this.$store.state.shared, "step_total", v);
      },
    },
    selectedDataset() {
      return this.edition.dataset;
    },
    selectedTable() {
      return this.edition.table;
    },
  },
  watch: {
    selectedDataset: {
      deep: true,
      handler() {
        if (this.edition.selectedDataset !== this.tableOf) {
          this.loadTables();
        }
      },
    },
    selectedTable: {
      deep: true,
      handler() {
        if (this.selectedTable !== this.schemaOf) {
          this.loadings.schema = true;
          this.$set(this.edition, "tableSchema", []);
          this.$store
            .dispatch("prep/tableConnectGetSchemaAmazonRedshift", {
              params: {
                dataset: this.edition.dataset,
                table: this.selectedTable,
              },
            })
            .then((response) => {
              this.$set(this.edition, "columns", []);
              this.schemaOf = String(this.selectedTable);
              this.$set(this.edition, "tableSchema", response.data.schemas);
              this.loadings.schema = false;
              this.key++;
            })
            .catch(() => {
              this.loadings.schema = false;
            });
        }
      },
    },
    metrics: {
      deep: true,
      handler(metrics) {
        this.edition.columns = [...this.dimensions, ...metrics];
        let hasMetric = metrics?.length > 0;
        let hasSteps = this.steps?.length > 0;
        if (hasMetric && (!hasSteps || this.steps?.every((s) => s.auto))) {
          this.generateProd();
        }
        this.$set(
          this.$store.state.shared.inputs,
          "columns",
          this.edition.columns.map((column) =>
            (this.$store.state.shared.inputs?.columns || []).filter(
              (c) => c.name === column
            ).length
              ? (this.$store.state.shared.inputs?.columns || []).filter(
                  (c) => c.name === column
                )[0]
              : {
                  name: column,
                  type: this.edition.tableSchema.filter(
                    (s) => s.name === column
                  )[0].type,
                }
          )
        );
      },
    },
    dimensions: {
      deep: true,
      handler(dimensions) {
        this.edition.dimensions = dimensions;
        this.edition.columns = [...this.dimensions, ...this.metrics];
        this.$set(
          this.$store.state.shared.inputs,
          "columns",
          this.edition.columns.map((column) =>
            (this.$store.state.shared.inputs?.columns || []).filter(
              (c) => c.name === column
            ).length
              ? (this.$store.state.shared.inputs?.columns || []).filter(
                  (c) => c.name === column
                )[0]
              : {
                  name: column,
                  type: this.colToType(
                    this.edition.tableSchema.filter((s) => s.name === column)[0]
                  ),
                }
          )
        );
      },
    },
    step(step) {
      let hasMetric = this.metrics.length > 0;
      let hasSteps = this.steps.length > 0;
      if (step.toString() === "2") {
        if (hasMetric && !hasSteps) {
          this.generateProd();
        }
      }
    },
    stepTotal: {
      deep: true,
      handler(v) {
        this.edition.total = v;
      },
    },
    edition: {
      deep: true,
      handler(edition) {
        this.$emit("update", edition);
        if (
          edition.columns.some(
            (c) =>
              this.$store.state.shared.inputs.columns
                .map((c) => c.name)
                .indexOf(c) === -1
          )
        ) {
          let columns = edition.columns.map((c) => ({
            name: c,
            unique: [],
            type: this.colToType(
              this.edition.tableSchema.filter((s) => s.name === c)[0]
            ),
          }));
          this.$store.dispatch("shared/setColumnsInput", columns).then(() => {
            this.$store.state.shared.uniqueUpdated = true;
          });
        }
      },
    },
  },
  mounted() {
    this.loadDatasets();
  },
  methods: {
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = [
        "Bytes",
        "KiB",
        "MiB",
        "GiB",
        "TiB",
        "PiB",
        "EiB",
        "ZiB",
        "YiB",
      ];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    },
    launchDryRun() {
      this.loadingDryRun = true;
      return this.$store
        .dispatch("prep/dryRunTableConnectAmazonRedshift", {
          params: {
            dryRun: true,
          },
          data: {
            ...this.edition,
            total: this.stepTotal,
          },
        })
        .then((r) => {
          this.loadingDryRun = false;
          this.totalBytesProcessed = this.formatBytes(
            parseInt(r?.data?.results[0]?.total_bytes_processed || false),
            2
          );
          this.$emit("query", r?.data?.query || "");
        });
    },
    bytesToSize(bytes) {
      let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes === 0) return "0 Byte";
      let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      let sz = (bytes / Math.pow(1024, i)) * 100;
      return Math.round(sz) / 100 + " " + sizes[i];
    },
    runDimensionScoring() {
      if (this.switches.dimension_scoring) {
        let data = this.edition;
        data["table_schema"] = data.tableSchema ?? [];
        data["dimensions"] = this.edition.tableSchema
          .filter((s) => this.isDimension(s))
          .map((s) => s.name);
        data["metrics"] = this.edition.tableSchema
          .filter((s) => !this.isDimension(s))
          .map((s) => s.name);
        return this.$store
          .dispatch("prep/runDimensionScoringSnowflake", {
            params: {
              dry_run: true
            },
            data: data,
          })
          .then((r) => {
            if (window.confirm(`Do you really want to enable this option ?}`)) {
              this.switches.dimension_scoring = true;
              return this.$store
                .dispatch("prep/runDimensionScoringSnowflake", {
                  params: {
                    dry_run: false,
                    project_id:
                      this.liveFlow.source.configuration.selectedProject,
                  },
                  data: data,
                })
                .then((r) => {
                  this.dimension_score = r.data.results;
                });
            } else {
              this.switches.dimension_scoring = false;
            }
          })
          .catch((err) => {
            this.switches.dimension_scoring = false;
          });
      }
    },
    handleColumnAddition(col) {
      let columnIndex = this.edition.tableSchema
        .map((s) => s.name)
        .indexOf(col.name);
      let nameOfLastDimension = this.edition.tableSchema.filter((s) =>
        this.isDimension(s)
      );
      nameOfLastDimension =
        nameOfLastDimension[nameOfLastDimension.length - 1]?.name ||
        this.edition.tableSchema[0]?.name;
      let lastDimensionIndex = this.edition.tableSchema
        .map((s) => s.name)
        .indexOf(nameOfLastDimension);
      if (columnIndex <= lastDimensionIndex) {
        if (this.dimensions.indexOf(col.name) === -1) {
          if (this.metrics.indexOf(col.name) === -1) {
            // console.log("New dimension")
            this.dimensions.push(col.name);
          } else {
            // console.log("Unselect column")
            this.metrics.splice(this.metrics.indexOf(col.name), 1);
            if (this.edition.aggregation[col.name]) {
              delete this.edition.aggregation[col.name];
            }
            // this.dimensions.push(this.metrics.splice(this.metrics.indexOf(col.name), 1)[0])
          }
        } else {
          // console.log("As metric")
          this.metrics.push(
            this.dimensions.splice(this.dimensions.indexOf(col.name), 1)[0]
          );
          if (!this.edition.aggregation[col.name]) {
            this.edition.aggregation[col.name] = "count_distinct";
          }
        }
      } else {
        if (this.metrics.indexOf(col.name) === -1) {
          if (this.dimensions.indexOf(col.name) === -1) {
            // console.log("New metric")
            this.metrics.push(col.name);
            if (!this.edition.aggregation[col.name]) {
              this.edition.aggregation[col.name] = "sum";
            }
          } else {
            // console.log("Unselect column")
            this.dimensions.splice(this.dimensions.indexOf(col.name), 1);
          }
        } else {
          // console.log("As dimension")
          this.dimensions.push(
            this.metrics.splice(this.metrics.indexOf(col.name), 1)[0]
          );
          if (this.edition.aggregation[col.name]) {
            delete this.edition.aggregation[col.name];
          }
        }
      }
    },
    generateProd(e = false) {
      if (e) e.preventDefault();
      let metrics = [1, ...this.metrics];
      let i = 1;
      let steps = [];
      while (i < metrics.length) {
        let name = i === 1 ? metrics[i] : metrics[i] + " / " + metrics[i - 1];
        steps.push({
          Num: metrics[i],
          Denom: metrics[i - 1],
          Step_Name: name,
          Threshold: null,
          Step_Number: i,
          Unit: "",
          focusedDimension: "",
          excludedDimension: [],
          active: true,
          primary: true,
          auto: true,
        });
        i++;
      }
      this.total.step_function = "prod";
      this.total.output_unit_2 = steps[steps.length - 1].Num;
      this.steps = steps;
      this.edition.steps = this.steps;
    },
    loadDatasets() {
      if (!this.datasetsOfSelectedProject?.length) {
        this.loadings.datasets = true;
        this.$store
          .dispatch("prep/getAmazonRedshiftDatabaseList")
          .then((rest) => {
            this.datasetsOfSelectedProject = rest.data.databases;
            this.loadings.datasets = false;
            if (
              !this.edition.dataset ||
              this.datasetsOfSelectedProject.indexOf(this.edition.dataset) === -1
            ) {
              this.edition.dataset = this.datasetsOfSelectedProject[0];
            }
            this.loadTables();
          })
          .catch(() => {
            this.loadings.datasets = false;
          });
      } else {
        this.loadings.datasets = false;
      }
    },
    loadTables() {
      this.loadings.tables = true;
      this.$store
        .dispatch("prep/getAmazonRedshiftSchemaList", {
          params: {
            dataset: this.edition.dataset,
          },
        })
        .then((schemaResponse) => {
          return this.$store.dispatch("prep/getAmazonRedshiftTableList", {
            params: {
              dataset: this.edition.dataset,
              schema: schemaResponse.data.schemas[0],
            },
          });
        })
        .then((response) => {
          this.tablesOfSelectedDataset = response.data.tables;
          if (
            !this.edition.table ||
            this.tablesOfSelectedDataset.indexOf(this.edition.table) === -1
          ) {
            this.edition.table = this.tablesOfSelectedDataset[0];
          }
          
          // this.$set(this.edition, "columns", []);
          // this.schemaOf = String(this.selectedTable);
          // this.$set(this.edition, "tableSchema", response.data.schemas);
          // this.loadings.schema = false;
          // this.key++;


          this.tableOf = String(this.edition.dataset);
          this.loadings.tables = false;
        })
        .catch(() => {
          this.loadings.tables = false;
        });
    },
    isDimension(col) {
      if (
        ["date", "datetime", "string", "time", "timestamp", "json"].includes(
          col["type"].toLowerCase()
        )
      ) {
        return true;
      }
      return false;
    },
    colIcon(col) {
      return {
        date: "mdi-calendar-range",
        datetime: "mdi-calendar-range",
        int: "mdi-numeric",
        float: "mdi-decimal",
        string: "mdi-alphabetical-variant",
        other: "mdi-alphabetical-variant",
      }[this.colToType(col)];
    },
    colToType(col) {
      let { type } = col;
      type = type.toLowerCase();
      if (type === "datetime") {
        return "datetime";
      } else if (type === "date") {
        return "date";
      } else if (
        [
          "int",
          "smallint",
          "integer",
          "bigint",
          "tinyint",
          "byteint",
          "numeric",
        ].includes(type)
      ) {
        return "int";
      } else if (["float", "decimal", "float64"].includes(type)) {
        return "float";
      }
      return "string";
    },
    columnColor(column) {
      if (this.metrics.indexOf(column.name) > -1) {
        return "green";
      }
      if (this.dimensions.indexOf(column.name) > -1) {
        return "blue";
      }
      return "gray";
    },
    columnKind(column) {
      if (this.metrics.indexOf(column.name) > -1) {
        return "metric";
      }
      if (this.dimensions.indexOf(column.name) > -1) {
        return "dimension";
      }
      return "default";
    },
    close() {
      this.liveFlowFunnel = false;
      this.loadings.schema = false;
      this.$emit("close");
    },
    apply() {
      this.close();
      this.$emit("load");
      if (this.$store.state.compute.state === COMPUTE_STATES.NEEDED) {
        this.$emit("compute");
      }
    },
  },
};
</script>


<style lang="scss">
#live-flow-dialog-loading-container-amazon-redshift .dialog-header {
  background-color: var(--primary-color-dark-green);
  color: white;
}

#live-flow-dialog-loading-container-amazon-redshift .dialog-header .v-stepper__step--active {
  background-color: white;
  color: var(--primary-color-dark-green) !important;
}

#live-flow-dialog-loading-container-amazon-redshift .dialog-header .v-stepper__step--active * {
  color: var(--primary-color-dark-green) !important;
}

#live-flow-dialog-loading-container-amazon-redshift .dialog-header .v-stepper__step--active .v-stepper__label {
  color: var(--primary-color-dark-green) !important;
}


#live-flow-dialog-loading-container-amazon-redshift .v-stepper__step__step {
  background-color: transparent !important;
  border: 1px solid white;
  color: white !important;
}

#live-flow-dialog-loading-container-amazon-redshift .v-stepper__label {
  color: white !important;
}

#live-flow-dialog-loading-container-amazon-redshift .v-divider {
  background-color: white;
}

#live-flow-dialog-loading-container-amazon-redshift > .v-dialog__content > .v-dialog {
  width: auto;
  height: auto;
  min-height: 150px;
  min-width: 200px;
  max-width: 70vw;
  max-height: 80vh;
}

#live-flow-dialog-container-amazon-redshift > .v-dialog__content > .v-dialog {
  width: 70vw;
  height: 60vh;
  min-width: 400px;
  min-height: 400px;

  & > div {
    width: 100%;
    height: 100%;
    background: var(--color-white);
    display: flex;
    flex-direction: column;

    & > div:first-child {
      max-height: 70vh;
      flex: 1;
      padding-right: 3px;

      & > div:first-child {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        & + div {
          margin-top: 72px;
          overflow-y: scroll;
          box-sizing: border-box;
          max-height: calc(100% - 82px);

          &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            border-radius: 10px;
            opacity: .5;
          }
          /* Track */
          &::-webkit-scrollbar-track {
            background: transparent;
          }
          /* Handle */
          &::-webkit-scrollbar-thumb {
            opacity: .5;
            background: var(--datama-primary);
          }
          /* Handle on hover */
          &:hover {
            &::-webkit-scrollbar-thumb {
              opacity: 1;
            }
          }
          & > .v-stepper__content {
            //padding-bottom: 0;
          }
        }
      }

      & + div {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }

  & .v-chip {
    // overflow: visible;
    &::after {
      display: inline-block;
      position: absolute;
      content: '';
      top: 0;
      left: 10px;
      color: currentColor;
      font-size: 8px;
      text-transform: uppercase;
      line-height: 10px;
    }

    &.dimension {
      &::after {
        content: 'Dimension'
      }

      progress::-moz-progress-bar {
        background: var(--color-secondary);
      }

      progress::-webkit-progress-value {
        background: var(--color-secondary);
      }

      progress {
        color: var(--color-secondary);
      }
    }

    &.metric {
      &::after {
        content: 'Metric'
      }

      progress::-moz-progress-bar {
        background: 'green';
      }

      progress::-webkit-progress-value {
        background: 'green';
      }

      progress {
        color: 'green';
      }
    }

    progress {
      position: absolute;
      bottom: -5px;
      left: 0;
      // right:0;
      width: -webkit-fill-available;
      height: 12px;
      vertical-align: baseline;
    }
  }
}
</style>
